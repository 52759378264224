import React from 'react'
import "./footer.css";
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div classNameName="footer">
            <div className="row mt-5 " style={{ position: "relative" }}>
                <img src={require('../../images/footerimg.png')} className="footerimageclass " />
                <div className="col-12 text-center abutusdiv">
                    <div className="row justify-content-center ">
                        <div className="col-md-5 col-12 rotate_like" >
                            <h6 className="funfont borderradiuscurve youtube_like" style={{ width: "369px" }}>FOLLOW, SHARE
                                AND WATCH!</h6>
                            <img src={require('../../images/arrow.png')} className="arrow-img" />
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-5 col-9">
                            <span className="funfont borderradiuscurve"
                                style={{ fontSize: "40px", backgroundColor: "#F36C38", color: "white", padding: "1px" }}>
                                    <a href="https://www.facebook.com/spsmtr"><i
                                    className="footericon fab fa-facebook"></i></a>
                                    <a href="https://www.instagram.com/sunrise.playschool"><i className="footericon fab fa-instagram"></i></a><a href="https://www.youtube.com/@sunrisetimesmathura9064"><i
                                        className="footericon fab fa-youtube"></i></a>
                            </span>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-3 col-12 ">
                            <Link
                                className="funfont footeritemtext footer_top_content" style={{ textDecoration: "none" }} to="/contact">Contact Us<img src={require('../../images/headingsupport.png')} style={{ width: "2vw" }} /></Link>
                        </div>
                        <div className="col-md-3 col-12">
                            <Link
                                className="funfont footeritemtext footer_top_content" style={{ textDecoration: "none" }} to="/take-admission">Take Admission<img src={require('../../images/headingsupport.png')} style={{ width: "2vw" }} /></Link>
                        </div>
                        <div className="col-md-4 col-12 ">
                            <Link
                                className="funfont footeritemtext footer_top_content" style={{ textDecoration: "none" }} to="/about">Know More about us<img src={require('../../images/headingsupport.png')} style={{ width: "2vw" }} /></Link>
                        </div>
                    </div>
                    <div className="row justify-content-center ">
                        <div className="col-md-3 col-12">
                            <Link
                                className="funfont footeritemtext footer_top_content" style={{ textDecoration: "none" }} to="/gallery">Gallery<img src={require('../../images/headingsupport.png')} style={{ width: "2vw" }} /></Link>
                        </div>
                        <div className="col-md-3 col-12 ">
                            <Link
                                className="funfont footeritemtext footer_top_content" style={{ textDecoration: "none" }} to="/franchise">Franchise<img src={require('../../images/headingsupport.png')} style={{ width: "2vw" }} to="/career" /></Link>
                        </div>
                        <div className="col-md-3 col-12 ">
                            <Link
                                className="funfont footeritemtext footer_top_content" style={{ textDecoration: "none" }} to="/awards">Awards<img src={require('../../images/headingsupport.png')} style={{ width: "2vw" }} /></Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row studentfooter" style={{ marginTop: "-10px" }}>
                {/* <img src={require('../../images/kk.png')} /> */}
            </div>
            <div className="row " style={{ backgroundColor: "#009846" }}>
                <div className="col-md-6 col-12 text-left">
                    {/* <a href="digisidekick.com" style={{ textDecoration: "none" }}>
                        <p className="footer_bottom">Terms & Condition | Privacy Policy</p>
                    </a> */}
                    <a href="http://digisidekick.com/" style={{ textDecoration: "none", color: "white" }}>
                    Terms & Condition | Privacy Policy
                    </a>


                </div>
                <div className="col-md-6 col-12 text-right">
                    <a href="http://digisidekick.com/" style={{ textDecoration: "none", color: "white" }}>
                        Designed & Powered by Digi Sidekick
                    </a>
                   
                </div>
            </div>
        </div>
    )
}

export default Footer
