import React from 'react'
import { Helmet } from 'react-helmet'
import LazyLoad from 'react-lazyload'
import awarddata from "./awarddata"

const Awards = () => {
    return (
        <>
            <Helmet>
                <title>SunrisePlaySchool | Awards</title>
                <meta name="description" content="Sunrise Play School and Day Care is a premium preschool that runs under the guidance of education professionals with a combined experience of over 30 years. Our focus is on fostering learning experiences for students." />
                <meta name="keywords" content="Sunrise Play  School, Pre School, About"></meta>
            </Helmet>
            <div className="row align-items-center text-center aboutustopbg" id="contacttopbg">
                <div className="col-12 mt-5">
                    <h1>Awards</h1>
                    <p className="navlinkdesbtn2">Sunrise Play School and Day Care has been recognised by many national and international organisations for its work in kids' education. We at Sunrise Play School and Day Care are committed to continuing working towards making this world a better place by providing a world-class education to our younger generation.</p>
                </div>
            </div>

            {/* <section class="text-center pt-5" >
                <h2>Our Awards</h2>
            </section> */}
            <div className="col-12 text-center pt-5">
                    <h1 style={{ fontSize: "50px", fontWeight: "900", color: "#009846" }}>Our Awards</h1>
                </div>

            <div class="container py-5">
                <div class="gallery d-flex flex-wrap justify-content-center">

                    {
                        awarddata.map((item) => (

                            <>
                               
                                <a href={item.imgsrc}
                                    data-lightbox="models">
                                    <LazyLoad

                                        // height={10} 
                                        key={item.Id}
                                        height={100}
                                        // height='80%'
                                        // height={10}
                                        // offset={100000000}
                                        // offset={[-100, 100]}
                                        // debounce={3000}
                                        placeholder={
                                        <>
                                            <div className={`spinner-grow ${item.spinner} `} role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                        </>
                                        }

                                    >
                                        <img  src={item.imgsrc} />
                                    </LazyLoad>
                                    {/* <img src="https://images.pexels.com/photos/693429/pexels-photo-693429.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" /> */}
                                </a>
                            </>

                        ))

                    }


                </div>
            </div>
        </>

    )
}

export default Awards