import React from 'react';
import "./map.css"

const Map = () => {
    return (
            <div className="google-map ">
                <iframe src="https://maps.google.com/maps?q=sunrise%20play%20schoolGoverdhan%20Chauraha,%20Mathura&t=&z=13&ie=UTF8&iwloc=&output=embed" width="100%" height="100%" frameBorder={0} allowFullScreen aria-hidden="false" tabIndex={0} />
            </div>
    )
}

export default Map