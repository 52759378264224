let awarddata = [
    {
        Id: 1,
        imgsrc: require(`../../images/awards/1.png`),
        spinner:"text-primary",
    },
    {
        Id: 2,
        imgsrc: require(`../../images/awards/2.png`),
        spinner:"text-secondary",
    },
    {
        Id: 3,
        imgsrc: require(`../../images/awards/3.png`),
        spinner:"text-success",
    },
    {
        Id: 4,
        imgsrc: require(`../../images/awards/4.png`),
        spinner:"text-success",
    },
]


export default awarddata