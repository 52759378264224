import React,{useEffect} from 'react';
import "./franchise.css";
import { Helmet } from 'react-helmet';
import { useState } from 'react';
import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
import {Link} from "react-router-dom"

const Franchise = () => {


    // < !--Global site tag(gtag.js) - Google Ads: 10808994388 -- >
    {/* <script async src="https://www.googletagmanager.com/gtag/js?id=AW-10808994388"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'AW-10808994388');
</script> */}


    const [firstname, setFirstName] = useState();
    const [lastname, setLastName] = useState();
    const [phone, setphone] = useState();
    const [email, setEmail] = useState();
    const [message, setMessage] = useState();
    // const history = useNavigate();



    const submitData = async (e) => {
        e.preventDefault();
        axios.get(`http://api.digisidekick.com/api/Mail?Email=rajatgupta02607@gmail.com&Firstname=${firstname}&Lastname=${lastname}&Number=${phone}&Usermial=${email}&UserMessage=${message}&other=null`,)
            .then((res) => {
                console.log(res.data)
                // alert("Form has been submitted successfully, Our team will contact you in 24 hours.");

            }).catch((error) => {
                console.log(error)
                // alert("Form has been submitted successfully, Our team will contact you in 24 hours.");
            });

        setFirstName("");
        setLastName("");
        setphone('');
        setEmail("");
        setMessage("");
        

    }


    return (
        <div className="franchise">
            <Helmet>
                <title>Sunrise Play School and Day Care | Franchise</title>
                <meta name="description" content="Sunrise Play School and Day Care is a premium preschool that runs under the guidance of education professionals with a combined experience of over 30 years. Our focus is on fostering learning experiences for students." />
                <meta name="keywords" content="Sunrise Play School and Day Care, Pre School, Franchise"></meta>
            </Helmet>
            <div className="row align-items-center text-center franchisetopbg" id="franchisetopbg">
                <div className="HideInPhone col-md-5 col-12 order-md-1 order-2" style={{ padding: "100px 30px 0px 80px" }}>
                    <form id="contact-form" style={{ backgroundColor: "white", padding: "20px", color: "#009846", borderRadius: "3% 45% 13% 9%/35% 9% 7% 10%" }}  >
                        <div className="form-row text-center">
                            <div className="col-12">
                                <h4 >Get free consultancy
                                    from Experts</h4>
                            </div>
                            <div className="col">
                                <input  id="FirstName" maxlength="15" type="text" onkeypress="return /[a-z]/i.test(event.key)" className="form-control franchise_form_style" placeholder="First Name" value={firstname} onChange={(e) => setFirstName(e.target.value)} required />
                            </div>
                            <div className="col">
                                {/* <!-- <label for="exampleFormControlInput1">Last Name</label> --> */}
                                <input  id="LastName" maxlength="15" onkeypress="return /[a-z]/i.test(event.key)" type="text" className="form-control franchise_form_style" placeholder="Last Name" value={lastname} onChange={(e) => setLastName(e.target.value)} required />
                            </div>
                        </div>
                        <div className="form-row  text-left" style={{ marginTop: "5px", marginBottom: "5px", borderRadius: "2% 66% 11% 41% / 34% 21% 52% 19%" }}>
                            <div className="col">
                                {/* <!-- <label for="exampleFormControlInput1">Phone Number</label> --> */}
                                <input  onkeypress="return /[0-9]/i.test(event.key)" type="number" className="form-control franchise_form_style" id="Number" maxlength="10" placeholder="Contact Number" value={phone} onChange={(e) => setphone(e.target.value)} required />
                            </div>

                        </div>
                        <div className="form-group text-left">
                            {/* <!-- <label for="exampleFormControlInput1">Email address</label> --> */}
                            <input  type="email" className="form-control franchise_form_style" id="Email" placeholder="Email ID" value={email} onChange={(e) => setEmail(e.target.value)} required />
                        </div>
                        <div className="form-group text-left">
                            {/* <!-- <label for="exampleFormControlTextarea1">Message</label> --> */}
                            <textarea  style={{ border: "1px solid black", borderRadius: "5% 66% 11% 41% / 34% 21% 52% 19%" }} className="form-control" id="Message" rows="3" placeholder="Feel Free To Talk To Us" value={message} onChange={(e) => setMessage(e.target.value)} required ></textarea>
                        </div>
                        <button id="submit" onClick={submitData} style={{ borderRadius: "3% 45% 13% 9%/35% 9% 7% 10%", backgroundColor: "#F36C38", color: "white", textAlign: "center", textDecoration: "none", cursor: "pointer" }} className="form-control borderradiuscurve">
                            Send Message
                        </button>
                    </form>
                </div>
                <div className="col-md-7 col-12 order-md-2 order-1 headertopcontentfranchise">
                    <div style={{ backgroundColor: "rgba(255, 255, 255)", color: "#F36C38", padding: "20px", borderRadius: "3% 45% 13% 9%/35% 9% 7% 10%" }}>
                        <h4 style={{ fontWeight: "700", color: "#58595B", }}>LEVERAGE OUR EXPERTISE IN EARLY EDUCATION. BRING THE WORLD'S TOP PRESCHOOL EDUCATION TO YOUR AREA!</h4>
                        <h2 style={{ fontWeight: "800" }}>OWN AN Sunrise Play School and Day Care</h2>
                        <div className="row">
                            <div className="col-6">
                                <Link className="nav-link" style={{ borderRadius: "3% 45% 13% 9%/35% 9% 7% 10%", backgroundColor: "#009846", color: "white" }} to="/contact">All you need to get started</Link>
                            </div>
                            <div className="col-6">
                                <Link className="nav-link" style={{ borderRadius: "3% 45% 13% 9%/35% 9% 7% 10%", backgroundColor: "transparent", color: "#009846", border: "2px solid #009846" }} to="/contact">Benefits of owning Sunrise Play School and Day Care</Link>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="row" style={{ position: "relative" }}>

                <div className="col-12 text-center abutusdiv">
                    <h1 className="aboutusfranchise"><span className="funfont" style={{ fontSize: "40px" }}>Sunrise Play School and Day Care</span><br />Join forces with India's most cutting-edge play school chain.</h1>
                    <p style={{ fontSize: "25px", color: "#58595B" }}>This is appropriate for people who want to launch a long-term business that has the potential to generate steady returns. You will need a partner when starting a play school that has knowledge of how to manage a successful play school franchise.</p>
                    <span style={{ color: "#F36C38", fontSize: "25px" }}>Sunrise Play School and Day Care and Day Care is the ideal companion for your exciting new adventure.</span>
                </div>
            </div>
            {/* <Home/> */}
            <div className="col-12 text-center abutusdiv">
                <h1 style={{ fontSize: "55px", fontWeight: "900", color: "#009846" }}>Why choose <span className="funfont" style={{ fontSize: "55px" }}>Sunrise Play School and Day Care franchise</span> ?</h1>
            </div>
            <div className="row">
                <div className="col-md-4 col-12 mt-4 img_div1" >
                    <div style={{ padding: "25px", position: 'absolute', bottom: "0" }}>
                        <div className="borderradiuscurve text-center" style={{ backgroundColor: "#F47736", padding: "10px 20px" }}>
                            <h2 style={{ color: "white", fontWeight: "800" }}>Set Up</h2>
                            <ul style={{ color: "white" }} class="text-left">
                                <li style={{ color: "white" }}>coordinating site visits and offering advice on potential sites</li>
                                <li style={{ color: "white" }}>releasing a unique exterior and interior design</li>
                                <li style={{ color: "white" }}>preparing and concluding legal contracts</li>
                            </ul>
                        </div>
                    </div>
                </div> <div className="col-md-4 col-12 mt-4 img_div2" >
                    <div style={{ padding: "25px", position: 'absolute', bottom: "0" }}>
                        <div className="borderradiuscurve text-center" style={{ backgroundColor: "#F47736", padding: "10px 20px" }}>
                            <h2 style={{ color: "white", fontWeight: "800" }}>Marketing</h2>
                            <ul style={{ color: "white" }} class="text-left">
                                <li style={{ color: "white" }}>Designing promotional items like invitations and posters</li>
                                <li style={{ color: "white" }}>Social media promotion through videos and regular posts</li>
                                <li style={{ color: "white" }}>Monthly admission leads will be provided</li>
                            </ul>

                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-12 mt-4 img_div3" >
                    <div style={{ padding: "25px", position: 'absolute', bottom: "0" }}>
                        <div className="borderradiuscurve text-center" style={{ backgroundColor: "#F47736", padding: "10px 20px" }}>
                            <h2 style={{ color: "white", fontWeight: "800" }}>Day to Day</h2>
                            <ul style={{ color: "white" }} class="text-left">
                                <li style={{ color: "white" }}>Assistance with ordering employees and school supplies</li>
                                <li style={{ color: "white" }}>Developing a management aid for ease of use</li>
                                <li style={{ color: "white" }}>Support with curriculum and study materials for all classes</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 text-center learnwilldiv" style={{ padding: "70px" }}>
                <h1 style={{ fontSize: "40px", fontWeight: "900", color: "#009846" }}>What all you need to get started?</h1>
                <p style={{ fontSize: "21px", color: "#F36C38" }}>To start a play school firstly you need commitment</p>
            </div>
            <div className="row text-center justify-content-around">
                <div class="col-md-3 col-12 borderradiuscurve  align-items-center" style={{ backgroundColor: "#00A0E3", padding: " 40px 20px " }}>
                    <h2 style={{ color: "white", fontWeight: 800 }}>Long Term Vision</h2>
                    <p style={{ color: "white", fontSize: "17px" }} class="text-center">Assuming one has a long-term outlook for the endeavor, partners must recognise that this is a business with continuous profits in order to join the franchise network.
</p>
                </div>
                {/* <div class="col-md-3 col-12 borderradiuscurve " style={{ backgroundColor: "#009846", padding: " 40px 20px " }}>
                    <h2 style={{ color: "white", fontWeight: 800 }}>Investment</h2>
                    <p style={{ color: "white", fontSize: "17px" }} class="text-center">The initial investment amount varies
                        from 7 Lakh* to 15 Lakh*. It depends
                        upon the size of the pre-school and the city in which you are
                        present in.</p>
                </div> */}
                <div class="col-md-3 col-12 borderradiuscurve" style={{ backgroundColor: "#E5097F", padding: " 40px 20px " }}>
                    <h2 style={{ color: "white", fontWeight: 800 }}>Management</h2>
                    <p style={{ color: "white", fontSize: "17px" }} class="text-center">We will assist you in day-to-day management by assisting with marketing initiatives and providing educational materials for the kids. Also, we'll give you early education industry updates and assist you in adjusting to them.</p>
                </div>
            </div>
            <div className="row" style={{ padding: "100px 50px" }}>
                <div className="col-12 col-md-3 text-center" style={{ paddingTop: "60px" }}>
                    <p className="catchup"><span className="borderradiuscurve" style={{ backgroundColor: "#F36C38", color: "white", padding: "5px", fontSize: "30px" }}>CATCH UP</span>
                    </p>
                    <br />
                    <p className="fun"><span className="borderradiuscurve funfont" style={{ backgroundColor: "#00A0E3", color: "white", padding: "5px", fontSize: "47px" }}>&amp; Join the
                        Fun</span></p>
                </div>
                <div className="col-12 col-md-9 text-center borderradiuscurve p-4" style={{ backgroundColor: "#009846", color: "white" }}>
                    <p style={{ fontSize: "25px", color: "white" }}>Any of our incredible events you missed? Our Facebook page lists all of the Sunrise Play School and Day Care EVENTS. For frequent updates and learning, follow us.</p>
                    <a className="btn borderradiuscurve" style={{ backgroundColor: "white", color: "#009846", fontWeight: "900" }} href="https://www.facebook.com/spsmtr/">Follow Us on Facebook</a>
                </div>
            </div>
            <div className="row text-center" style={{ padding: '50px 0px' }} >
                <div className="col-12 text-center">
                    <h1 className="funfont homeopenplayschoolh1">
                        <img src={require("../../images/headingsupport.png").default} style={{ width: '3vw', transform: 'rotate(180deg)' }} />
                        Open Your Own
                        <span className="funfont" style={{ fontSize: '50px' }}>Play School</span><img
                            src={require("../../images/headingsupport.png").default} style={{ width: '3vw', transform: 'rotate(0deg)' }} />
                    </h1>
                </div>
                <div className="col-12 text-center firstfranchisediv">
                    <div className="row">
                        <div className="col-12 col-md-6 Qualitybox" >
                            <div className="borderradiuscurve" style={{ backgroundColor: '#e5097eb4', padding: '30px' }} >
                                <h2 style={{ color: "white", fontWeight: "700" }}>Make an Impact to help people receive quality
                                    education</h2>
                                <h5 style={{ color: 'white' }}>For those who wish to launch a business that is long-term in nature, investment friendly, and has the ability to provide reliable returns, the preschool franchise business is a good fit. Sunrise Play School and Day Care is the ideal ally to support you.

                                </h5>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 text-center secondfranchisediv">
                    <div className="row">
                        <div className="col-12 col-md-6">
                        </div>
                        <div className="col-12 col-md-6 Qualitybox">
                            <div className="borderradiuscurve" style={{ backgroundColor: '#009fe3b6', padding: '30px' }} >
                                <h2 style={{ color: "white", fontWeight: "700" }}>Spend Time with small children
</h2>
                                <h5 style={{ color: 'white' }}>Spend time with young kids. Play schools can be an excellent opportunity for housemakers to spend time with kids. They can delight in it, and it gives the kids a motherly touch, especially in daycare. It implies that both the child and the housemakers can gain from their interaction.

                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 text-center thirdfranchisediv">
                    <div className="row">
                        <div className="col-12 col-md-6 Qualitybox">
                            <div className="borderradiuscurve" style={{ backgroundColor: '#009847b6', padding: '30px' }} >
                                <h2 style={{ color: "white", fontWeight: "700" }}>High and Long-Term Earning Potential</h2>
                                <h5 style={{ color: 'white' }}>Sunrise Play School and Day Care franchises can generate revenue through various streams, including tuition fees, after-school programs, summer camps, and other activities. This diversity in revenue streams can help ensure steady earnings for the long term.


                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Franchise
