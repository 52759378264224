import axios from "axios";
import React, { useState } from "react";
import { Helmet } from "react-helmet";

const TakeAdmission = () => {
  const [firstname, setFirstName] = useState();
  const [lastname, setLastName] = useState();
  const [phone, setphone] = useState();
  const [email, setEmail] = useState();
  const [message, setMessage] = useState();

  const programmeOfferedData = [
    {
      Id: 1,
      title: "Fun and Entertaining Curriculum",
      // programmedesc: `We offer a Pre-Kindergarten (PG) program designed for children to prepare for formal schooling. Our PG program provides numerous benefits, including opportunities for socialization, language development, and early learning skills.  `,
      color: "#009846",
    },
    {
      Id: 2,
      title: "World Class Education",
      // programmedesc: `The foundation for all successive educational levels is laid in nursery schools. With enjoyable games and activities, kids learn the fundamentals of education, including the alphabet, numbers, nursery rhymes, etc.`,
      color: "#00A0E3",
    },
    {
      Id: 3,
      title: "Learnings through Storytelling",
      // programmedesc: `This program is defined for kids in the age group of 4-5. The Nursery programme allows the abilities to be refined through experimentation with the next level of learning when previously learned concepts meet application in the LKG programme.`,
      color: "#E5097F",
    },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
   
    axios
      .post(`https://admin.innov8educare.in/api/sunriseplayschool/lead`, {
        firstname,
        lastname,
        phone,
        email,
        message,
      })
      .then((res) => {
        alert("Form has been submitted successfully");
        console.log(res.data);
      })
      .catch((error) => {
        // alert("Form has been submitted successfully, Our team will contact you in 24 hours.");
        console.log(error);
      });
    setFirstName("");
    setLastName("");
    setphone("");
    setEmail("");
    setMessage("");
    // history.push("/thank")
  };

  return (
    <>
      <Helmet>
        <title>Sunrise Play School and Day Care | Take Admission</title>
        <meta
          name="description"
          content="Sunrise Play School and Day Care is a premium preschool that runs under the guidance of education professionals with a combined experience of over 30 years. Our focus is on fostering learning experiences for students."
        />
        <meta
          name="keywords"
          content="Sunrise Play School and Day Care, Pre School, Franchise"
        ></meta>
      </Helmet>
      <div
        className="row align-items-center text-center aboutustopbg"
        id="contacttopbg"
      >
        <div className="col-12 mt-5">
          <h1>Take Admission</h1>
          <p className="navlinkdesbtn2">
            Sunrise Play School and Day Care is the best place for your children
            to start their learning journey. We at Sunrise Playschool and Day
            Care are committed to impacting kids' life with a world-class
            education and teachings.
          </p>
        </div>
      </div>
      <div className="col-12 text-center pt-5 ">
        <h1 style={{ fontSize: "55px", fontWeight: "900", color: "#009846" }}>
          Why{" "}
          <span className="funfont " style={{ fontSize: "55px" }}>
            Sunrise Play School
          </span>{" "}
          ?
        </h1>
      </div>
      <div className="row text-center px-5 justify-content-around  learnboxx ">
        {programmeOfferedData.map((p) => (
          <>
            <div className="col-md-4 col-12 p-5 d-flex align-items-stretch">
              <div
                className="borderradiuscurve "
                key={p.Id}
                style={{ backgroundColor: `${p.color}`, padding: "40px 20px" }}
              >
                <h2 style={{ color: "white", fontWeight: "800" }}>{p.title}</h2>
                {/* <p className="text-white">
                                        {p.programmedesc}
                                    </p> */}
              </div>
            </div>
          </>
        ))}
      </div>
      <div className="container contact">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <h2
                className="formtophead"
                style={{ fontWeight: "900", textAlign: "center" }}
              >
                Feel free to contact us
              </h2>
              <p style={{ textAlign: "center", marginBottom: "0px" }}>
                Sunrise Play School and Day Care - A foundation for your child's
                future.
              </p>
            </div>
            <div className="col-md-4 col-12">
              <h2 className="getintouchh2">Get in touch</h2>
              <div style={{ marginBottom: "5px" }}>
                <div className="row">
                  <div className="col-12">
                    <img
                      src={require("../../images/bg.png").default}
                      className="contacticonlogo"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-1">
                    <i
                      style={{ fontSize: "18px" }}
                      className="fas fa-map-marker-alt"
                    ></i>
                  </div>
                  <div className="col-10 footercontact">
                    <p>
                      Goverdhan Chauraha,
                      <br />
                      Mathura
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-1">
                    <i
                      style={{ fontSize: "18px" }}
                      className="fas fa-phone-alt"
                    ></i>
                  </div>
                  <div className="col-10 footercontact">
                    <p>9897903674, 7895341096</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-1">
                    <i
                      style={{ fontSize: "18px" }}
                      className="fas fa-envelope"
                    ></i>
                  </div>
                  <div className="col-10   footercontact">
                    <p>sunrise0565@gmail.com</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-8 col-12 align-self-center mt-3">
              <div>
                <form id="contact-form" onSubmit={handleSubmit} method="post">
                  <div className="form-row">
                    <div className="col">
                      <label for="exampleFormControlInput1">First Name</label>
                      <input
                        id="FirstName"
                        maxlength="15"
                        name="firstname"
                        className="contact_form_style form-control"
                        type="text"
                        value={firstname}
                        onChange={(e) => setFirstName(e.target.value)}
                        placeholder="First name"
                        required
                      />
                      {/* <input required="" className="contact_form_style form-control" name="phone"  value={formValue.phone} onChange={handleChange}  type="text" id="Number" maxlength="10" placeholder="Please Enter Your Number" /> */}
                      {/* <input placeholder='First Name' onChange={(e) => setFirstName(e.target.value)}/> */}
                    </div>
                    <div className="col">
                      <label for="exampleFormControlInput1">Last Name</label>
                      <input
                        id="LastName"
                        maxlength="15"
                        name="lastName"
                        className="contact_form_style form-control"
                        value={lastname}
                        onChange={(e) => setLastName(e.target.value)}
                        type="text"
                        placeholder="Last name"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-row phone_div ">
                    <div className="col">
                      <label for="exampleFormControlInput1">Phone Number</label>
                      <input
                        className="contact_form_style form-control"
                        name="phone"
                        value={phone}
                        onChange={(e) => setphone(e.target.value)}
                        type="text"
                        id="Number"
                        maxlength="10"
                        placeholder="Please Enter Your Number"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label for="exampleFormControlInput1">Email address</label>
                    <input
                      className="contact_form_style form-control"
                      type="email"
                      name="email"
                      id="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="name@example.com"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label for="exampleFormControlTextarea1">Message</label>
                    <textarea
                      className="contact_form_style form-control"
                      name="message"
                      id="Message"
                      rows="3"
                      placeholder="Feel Free To Talk To Us"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      required
                    ></textarea>
                  </div>
                  {/* {showErrors ? errorMessages.map((item, index) => {
                                        return <ul key={index}>{item}</ul>;
                                    }) : null} */}
                  <button
                    id="submit"
                    className="form-control borderradiuscurve"
                  >
                    Send Message
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TakeAdmission;
