import React from "react";
import { Link } from "react-router-dom";
import "./navbar.css";
import img3 from "../../images/sunrise-logo.jpg";
import img2 from "../../images/headingsupport.png";
const Navbar = () => {
  function opennav() {
    document.getElementById("mySidenav").style.width = "100vw";
    // document.getElementById("main").style.marginLeft = "0";
  }

  function closenav() {
    document.getElementById("mySidenav").style.width = "0";
  }

  return (
    <>
      <div
        id="mySidenav"
        className="sidenav sidenavdesign phonebackground"
        style={{ whiteSpace: "nowrap" }}
      >
        <a href="javascript:void(0)" className="closebtn" onClick={closenav}>
          &times;
        </a>
        <Link to="/" onClick={closenav}>
          Home
        </Link>
        <Link to="/programs-offered" onClick={closenav}>
          Programs
        </Link>
        <Link to="/franchise" onClick={closenav}>
          Franchise
        </Link>
        <Link to="/awards" onClick={closenav}>
          Awards
        </Link>
        <Link to="/gallery" onClick={closenav}>
          Gallery
        </Link>
        <Link to="/about" onClick={closenav}>
          About Us
        </Link>
        <Link to="/contact" onClick={closenav}>
          Locate Us
        </Link>
        <Link to="/take-admission" onClick={closenav}>
          Take Admission
        </Link>
      </div>
      <div
        id="mobilenavbar"
        className="HideInDesktop topfixedbar"
        style={{
          backgroundColor: "#fff",
          zIndex: "2",
          borderBottom: "1px solid #009846",
          position: "fixed",
          width: "100%",
        }}
      >
        <div
          className=" d-flex justify-content-between"
          style={{
            padding: "0px 0px 1px 0px",
            marginLeft: "5px",
            height: "12vh",
          }}
        >
          <div
            id="mobilelogoimg"
            style={{
              float: "left",
              color: "white",
              fontSize: "2rem",
            //   marginTop: "15px",
            }}
          >
            <img
              img
              id="mobilenavlogo"
              src={img3}
              style={{ height: "11vh" }}
              alt=""
            />
          </div>
          <div style={{ padding: "5px" ,display:"flex",alignItems:"center"}}>
            <span
              id="btnmenuhide"
              style={{
                fontSize: "30px",
                cursor: "pointer",
                color: "green",
                float: "right",
              }}
              onClick={opennav}
            >
              &#9776;
              <img
                src={img2}
                style={{ width: "5vw", marginTop: "-5px" }}
              />{" "}
            </span>
          </div>
        </div>
      </div>
      <nav
        className="navbar navbar-expand-lg destopnavbarmain HideInPhone"
        style={{ position: "fixed", width: "100%", zIndex: "1" }}
      >
        <div className="container-fluid ">
          <Link className="navbar-brand" style={{ color: "white" }} to="/">
            <img
              className="borderradiuscurve"
              id="desktopnavlogo"
              src={img3}
              style={{ height: "100px", backgroundClip: "white" }}
              alt=""
            />
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </Link>
          <div
            className="collapse navbar-collapse "
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav ml-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className=" nav-link desktopnavlink " to="/">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className=" nav-link desktopnavlink "
                  to="/programs-offered"
                >
                  {" "}
                  Programs Offered
                </Link>
              </li>
              <li className="nav-item">
                <Link className=" nav-link desktopnavlink " to="/franchise">
                  Franchise
                </Link>
              </li>
              <li className="nav-item">
                <Link className=" nav-link desktopnavlink " to="/awards">
                  Awards
                </Link>
              </li>
              <li className="nav-item">
                <Link className=" nav-link desktopnavlink " to="/gallery">
                  Gallery
                </Link>
              </li>
              <li className="nav-item">
                <Link className=" nav-link desktopnavlink " to="/about">
                  About Us
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/contact" className="nav-link  desktopnavlink">
                  Locate us
                </Link>
              </li>
              <li className="nav-item d-flex flex-row">
                <Link className="nav-link navlinkdesbtn" to="/take-admission">
                  Take Admission{" "}
                </Link>
                <img
                  src={img2}
                  style={{ width: "2vw" }}
                  alt=""
                />
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
