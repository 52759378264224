import React, { useEffect } from "react";
import "./slider.css";
import img1 from "../../images/shuslider1.png";
import img2 from "../../images/shuslider2.png";
import img3 from "../../images/shuslider3.png";
import img4 from "../../images/minishuslider1.png";
import img5 from "../../images/minishuslider2.png";
import img6 from "../../images/minishuslider3.png";
const Slider = () => {
  return (
    <>
      <>
        <section className="banner" style={{ width: "100%" }}>
          <div
            id="carouselExampleControls"
            class="carousel slide"
            data-bs-ride="carousel"
          >
            <div class="carousel-inner">
              {[
                [img1, img4],
                [img2, img5],
                [img3, img6],
              ].map((p, i) => (
                <>
                  <div
                    className={
                      i === 0 ? "carousel-item active" : "carousel-item"
                    }
                    style={{ width: "100vw" }}
                  >
                    <img src={p[0]} class="d-lg-block d-none w-100" alt="..." />
                    <img src={p[1]} class="d-block d-lg-none w-100" alt="..." />
                  </div>
                </>
              ))}
            </div>
            <button
              class="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="prev"
            >
              <span
                class="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button
              class="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="next"
            >
              <span
                class="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </section>
      </>
    </>
  );
};

export default Slider;
