import React from "react";
import "./welcome.css";
import Child from "../child/Child";
import Home from "../home/Home";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import img1 from "../../images/shuslider1.png";
import img2 from "../../images/shuslider2.png";
import img3 from "../../images/shuslider3.png";
import img4 from "../../images/minishuslider1.png";
import img5 from "../../images/minishuslider2.png";
import img6 from "../../images/minishuslider3.png";
import img7 from "../../images/bg.png";
import img8 from "../../images/bg2.png";
import Slider from "../slider/Slider";
const Welcome = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      // slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 1,
      slidesToSlide: 3, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 767, min: 464 },
      items: 1,
      slidesToSlide: 3, // optional, default to 1.
    },
  };
  return (
    //  className="ShowInPhone HideInDesktop"
    <div className="welcome">

      <Slider/>
      
      <div class="row py-5" style={{ position: "relative" }}>
        <img class="topimg3" src={img7} alt="" />
        <img class="topimage2" src={img8} alt=""></img>

        <div className="row justify-content-center align-items-center">
          <div className="col-12 col-lg-8 text-center abutusdiv py-5 px-5 text-center">
            <h1 style={{ fontSize: "40px", fontWeight: 900, color: "#009846" }}>
              Welcome to{" "}
              <span className="funfont break" style={{ fontSize: "40px" }}>
                Sunrise Play
              </span>
              <span className="funfont break" style={{ fontSize: "40px" }}>
                School and Day Care
              </span>
            </h1>
            <p style={{ fontSize: "20px", padding: "5%" }}>
              Sunrise Play School and Day Care is quartered on the four
              educational pillars- learning to know, learning to do, learning to
              live together, and learning to be. We firmly believe education's
              duty is to bring out a child's nobility and maximize their
              potential. We do this through a carefully crafted environment that
              fosters not only their mind but also their heart and soul.
            </p>
          </div>
        </div>
      </div>
      <Child />
      <Home />
    </div>
  );
};

export default Welcome;
