import React from 'react'

const New = ({ data, morenumber, lessnumber }) => {
    // console.log(data.filter(o=>o.id >= morenumber && o.id <= lessnumber ))
    return (
        <div className="row text-center mt-2 justify-content-around learnboxx">

            {/* {
                
                data.filter(o=>o.id >= morenumber && o.id <= lessnumber ).map((item) => {
                return (<> */}
            <div className="col-md-3 text-white col-12 borderradiuscurve" style={{ backgroundColor: `#009846`, padding: "40px 20px" }}>
                {/* <div className="col-md-3 col-12 borderradiuscurve" style={{ backgroundColor: `${item.color}`, padding: "40px 20px" }}> */}
                {/* <h2 style={{ color: "white", fontWeight: "800" }}>{item.title}</h2> */}
                <h2 ><b style={{color:'white'}}>Innovation</b></h2>
                <p className="text-white">
                    Our curriculum is predicated on the "Exploration, Practice, and Repetition" technique, with the teachers serving as a guide to steer the students in the right direction. This allows your child to study, experiment, and create solutions on their own. 
                </p>
            </div>
            {/* </>)
                })
            } */}

            <div className="col-md-3 col-12 borderradiuscurve" style={{ backgroundColor: `#00A0E3`, padding: "40px 20px" }}>
                {/* <div className="col-md-3 col-12 borderradiuscurve" style={{ backgroundColor: `${item.color}`, padding: "40px 20px" }}> */}
                <h2><b className="text-white">Creativity</b> </h2>
               
                <p className="text-white">
                Activities like nature exploration, free play, storytime, painting and music allow your child to express themselves freely. This develops a creative cog in their brain and helps them to realize their maximum potential.  
                </p>
            </div>

            <div className="col-md-3 col-12 borderradiuscurve" style={{ backgroundColor: `#E5097F`, padding: "40px 20px" }}>
                {/* <div className="col-md-3 col-12 borderradiuscurve" style={{ backgroundColor: `${item.color}`, padding: "40px 20px" }}> */}
                <h2><b className="text-white">Critical Thinking</b> </h2>
               
               <p className="text-white">
               Our curriculum serves your child’s quest for knowledge through fun activities like jigsaw puzzles, sorting games, etc. Through this, they become active learners rather than passive receivers of knowledge in order to engage in reflection and critical thinking.
               </p>
            </div>




        </div>
    )
}

export default New
