import React, { useState, useEffect } from 'react'
import New from '../changeComponents/New';

const Child = () => {

    const data = [
        { id: 1, title: "content1", list: "list1", color: "#009846" },
        { id: 2, title: "content2", list: "list2", color: "#00A0E3" },
        { id: 3, title: "content3", list: "list3", color: "#E5097F" },
       
       
        { id: 4, title: "content4", list: "list4", color: "#009846" },
        { id: 5, title: "content5", list: "list5", color: "#00A0E3" },
        { id: 6, title: "content6", list: "list6", color: "#E5097F" },
        { id: 7, title: "content7", list: "list7", color: "#009846" },
        { id: 8, title: "content8", list: "list8", color: "#00A0E3" },
        { id: 9, title: "content9", list: "list9", color: "#E5097F" },
        { id: 10, title: "content10", list: "list10", color: "#009846" },
        { id: 11, title: "content11", list: "list11", color: "#00A0E3" },
        { id: 12, title: "content12", list: "list12", color: "#E5097F" },
    ]

    const [select, setSelect] = useState();

    return (
        <div className="child py-5">
            <div className="col-12 text-center learnwilldiv">
                <h1 style={{ fontSize: "40px", fontWeight: 900, color: "#009846" }} className="mb-5">What will your child <span className="funfont" style={{ fontSize: "40px" }}>learn ?</span></h1>
                {/* <p style={{ fontSize: "25px" }}>Select your child's age group:</p>
                <select value={select} onChange={e => setSelect(e.target.value)} id="selectyear" style={{ fontSize: "20px", borderRadius: "10px", border: "1px solid #009846", color: "#009846", marginTop: "-15px" }} >
                    <option>1.5 - 2.5 Years (Play Group)</option>
                    <option>2.5 - 3.5 Years (Nursery)</option>
                    <option>3.5 - 4.5 Years (Lower KG)</option>
                    <option>4.5 - 5.5 Years (Upper KG)</option>
                </select> */}
            </div>
            <div >

                {/* dynamic data For Future */}
                
                {
                    select ==null && <New data={data} morenumber={"0"} lessnumber={"3"}  />
                }
                {
                    select === "1.5 - 2.5 Years (Play Group)" && <New data={data} morenumber={"0"} lessnumber={"3"}  />
                }
                {
                    select === "2.5 - 3.5 Years (Nursery)" && <New data={data} morenumber={"4"} lessnumber={"6"} />
                }
                {
                    select === "3.5 - 4.5 Years (Lower KG)" && <New data={data} morenumber={"7"} lessnumber={"9"} />
                }
                {
                    select === "4.5 - 5.5 Years (Upper KG)" && <New data={data} morenumber={"10"} lessnumber={"12"} />
                }





                

            </div>

        </div>
    )
}

export default Child
