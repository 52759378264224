import React from "react";
import "./gallary.css";
import { Helmet } from "react-helmet";
import gallarydata, { lagreImage } from "./gallarydata";
import LazyLoad from "react-lazyload";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const Gallary = () => {
  return (
    <>
      <div classNameName="gallary">
        <Helmet>
          <title>SunrisePlaySchool | Gallery</title>
          <meta
            name="description"
            content="Sunrise Play School and Day Care is a premium preschool that runs under the guidance of education professionals with a combined experience of over 30 years. Our focus is on fostering learning experiences for students."
          />
          <meta
            name="keywords"
            content="SUnrise Play  School, Pre School, Gallary"
          ></meta>
        </Helmet>
        <div
          className="row align-items-center text-center gllerytopbgg"
          id="contacttopbg"
        >
          <div className="col-12 mt-5">
            <h1>Gallery</h1>
            <p className="navlinkdesbtn2">
              We believe that each child is a unique individual who needs a
              secure, caring, and stimulating atmosphere in which to grow and
              mature
            </p>
          </div>
        </div>
      </div>

      <div className="col-12 text-center  pt-5">
        <h1 style={{ fontSize: "50px", fontWeight: "900", color: "#009846" }}>
          Our Gallery
        </h1>
      </div>
      <div class="container py-5">
        <div class="gallery d-flex flex-wrap justify-content-center">
          {gallarydata.map((item) => (
            <>
              {/* <a href="https://images.pexels.com/photos/693429/pexels-photo-693429.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" */}
              <a href={item.imgsrc} data-lightbox="models">
                <LazyLoad
                  // height={10}
                  key={item.Id}
                  height={100}
                  // height='80%'
                  // height={10}
                  // offset={100000000}
                  // offset={[-100, 100]}
                  // debounce={3000}
                  placeholder={
                    <>
                      <div
                        className={`spinner-grow ${item.spinner} `}
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                    </>
                  }
                >
                  <img src={item.imgsrc} />
                </LazyLoad>
                {/* <img src="https://images.pexels.com/photos/693429/pexels-photo-693429.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" /> */}
              </a>
            </>
          ))}
        </div>
        <div class="gallery d-flex flex-wrap justify-content-center mt-3">
          {lagreImage.map((item) => (
            <>
              {/* <a href="https://images.pexels.com/photos/693429/pexels-photo-693429.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" */}
              <a href={item.imgsrc} data-lightbox="models">
                <LazyLoad
                  // height={10}
                  key={item.Id}
                  height={100}
                  // height='80%'
                  // height={10}
                  // offset={100000000}
                  // offset={[-100, 100]}
                  // debounce={3000}
                  placeholder={
                    <>
                      <div
                        className={`spinner-grow ${item.spinner} `}
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                    </>
                  }
                >
                  <img src={item.imgsrc} />
                </LazyLoad>
                {/* <img src="https://images.pexels.com/photos/693429/pexels-photo-693429.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" /> */}
              </a>
            </>
          ))}
        </div>
      </div>

      {/* <div className="row" style={{ display: "flex" }}>



                {

                    gallarydata.map((item) => (

                        <>



                            <Post  {...item} />



                        </>

                    ))

                } */}

      {/* </div> */}
    </>
  );
};

export default Gallary;
