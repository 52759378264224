import React from 'react';
import Child from '../child/Child';
import "./about.css";
import { Helmet } from 'react-helmet';
import img1 from "../../images/director.png"
import img2 from "../../images/headingsupport.png"
const About = () => {
    return (
        <div className="about">
            <Helmet>
                <title>SunrisePlaySchool | About</title>
                <meta name="description" content="Sunrise Play School and Day Care is a premium preschool that runs under the guidance of education professionals with a combined experience of over 30 years. Our focus is on fostering learning experiences for students." />
                <meta name="keywords" content="Sunrise Play  School, Pre School, About"></meta>
            </Helmet>

            <div className="row align-items-center text-center aboutustopbg" id="contacttopbg">
                <div className="col-12 mt-5">
                    <h1>About Us</h1>
                    <p className="navlinkdesbtn2">Sunrise Playschool and Day Care is a trailblazer in early childhood education and care. We are the most revolutionary preschool network in India. With creative culpability and mind growth, we are devoted to promoting child development across the country. We strive to help each child reach his or her full potential.</p>
                </div>
            </div>

            <div class="row py-5" style={{ position: "relative" }}>
                {/* <img class="topimg3" src={require("../../images/bg.png").default} alt="" />
                <img class="topimage2" src={require("../../images/bg2.png").default} alt=""></img> */}

                <div className="row justify-content-center ">
                    <div className="col-12 col-lg-8 text-center abutusdiv py-5 px-5 text-center">
                        <h1 style={{ fontSize: "40px", fontWeight: 900, color: "#009846" }}>Director's <span className="funfont" style={{ fontSize: "40px" }}>Desk</span></h1>

                        <div className="row">
                            <div className="col-md-4">
                                <img src={img1} alt="" className='img-fluid'/>
                                <h5 className='pt-1' ><span style={{color:'#E5097F',fontWeight:"900",fontSize: "30px"}}>Richa Sharma</span></h5>
                                <h6><b style={{color:"#00A0E3",fontWeight:"900",fontSize: "27px"}}>Director</b></h6>
                            </div>
                            <div className="col-md-8 text-start" style={{textAlign:"start"}} >
                                <p style={{ fontSize: "20px" }}>
                                Sunrise Play School and Day Care is a 21st-century school founded in 1996 by Richa and Nishant Sharma. They shared a passion for nurturing creativity and providing a free-flowing, energized environment where kids could learn and thrive. Today, their vision has grown into a large family of children, parents, staff, and associates who are committed to providing the best quality education and mentoring kids to become versatile human beings.
                                </p>
                            </div>


                        </div>







                        <p style={{ fontSize: "20px",textAlign:"start" }}>
                            Our team is dedicated to exposing children to various walks of life, global best practices, and innovative initiatives to prepare them for life's challenges. With several milestones achieved, we are proud to offer a secure and safe environment for our students to learn and grow.<br /><br/>
                            At Sunrise Public School, we believe that learning should be a joyful experience, and we strive to create an atmosphere where children love to come and learn. Join us on this journey of discovery and growth, and let us help your child unlock their potential and realize their dreams.


                        </p>
                    </div>
                </div>
            </div>




            <Child />



            <div className="row">
                <div className="col-md-6 col-12 mt-4 img_div22"  >
                    <div style={{ padding: "25px", position: 'absolute', bottom: "0" }}>
                        <div className="text-white borderradiuscurve text-center d-flex align-items-center flex-column justify-content-center" style={{ backgroundColor: "#F47736", padding: "10px 20px", height: '330px' }}>
                            <h2 style={{ color: "white", fontWeight: "800" }}>Vision</h2>
                            <p className="text-white">
                                We aim to create a child-centric approach to comprehensive education where the body, mind,
                                heart and spirit of a child grow into a complete and balanced human being who can contribute to
                                their family, society, country and the world.                            </p>

                        </div>
                    </div>
                </div> <div className="col-md-6 col-12 mt-4 img_div33" >
                    <div style={{ padding: "25px", position: 'absolute', bottom: "0" }}>
                        <div className="borderradiuscurve text-center d-flex align-items-center flex-column justify-content-center" style={{ backgroundColor: "#F47736", padding: "10px 20px", height: '330px' }}>
                            <h2 style={{ color: "white", fontWeight: "800" }}>Mission</h2>
                            <p className="text-white">
                                We aspire to provide an atmosphere in which each child receives personal assistance in order
                                to achieve their full academic, social, physical, and emotional potential.
                                We strive to give learning opportunities in a non-competitive, compassionate, and caring
                                atmosphere that nurtures the positive self-image of every child.
                            </p>


                        </div>
                    </div>
                </div>
                {/* <div className="col-md-4 col-12 mt-4 img_div3" >
                    <div style={{ padding: "25px", position: 'absolute', bottom: "0" }}>
                        <div className="borderradiuscurve text-center" style={{ backgroundColor: "#F47736", padding: "10px 20px", height:'330px' }}>
                        <h2 style={{ color: "white", fontWeight: "800" }}>Spiritual and Holistic Development</h2>
                            <p className="text-white">
                                As they share their surroundings with birds and butterflies, children learn to be one with nature.
                            </p>
                            <p className="text-white">
                                This experience is conducive to the child's spiritual and holistic development.
                            </p>

                        </div>
                    </div>
                </div> */}
            </div>


            {/* Principal of Education Service */}
            <div className="row text-center" style={{ padding: '50px 0px' }} >
                <div className="col-12 text-center pb-3">
                    <h1 className="funfont homeopenplayschoolh1" style={{ color: "#F36C38" }}>
                        <img src={img2} style={{ width: '3vw', transform: 'rotate(180deg)', }} />
                        Sunrise principle of education <span className="funfont" style={{ fontSize: '50px' }}>&nbsp;</span><img
                            src={img2} style={{ width: '3vw', transform: 'rotate(0deg)' }} />
                    </h1>
                </div>
                <div className="col-12 text-center firstfranchisediv">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-8 Qualitybox  justify-content-center" >
                            <div className="borderradiuscurve text-left" style={{ backgroundColor: '#e5097eb4', padding: '30px' }} >
                                {/* <h2 className="text-center" style={{ color: "white", fontWeight: "700" }}>Facilities</h2> */}
                                <p className="text-white">Sunrise Play School and Day Care is founded upon four building blocks of education and the principle of “compete with yourself”. The role of education is to bring out the nobility and maximise the potential of every child through a carefully planned environment that addresses all aspects of a child's development:
                                </p>
                                <ul>
                                    <li className=" text-white">Body</li>
                                    <li className=" text-white">Mind</li>
                                    <li className=" text-white">Heart</li>
                                    <li className=" text-white">Spirit</li>
                                </ul>
                                <p className="text-white">We nurture the kids with a balanced and rounded education. This means the development of both inner and outer powers in a carefully designed environment.</p>
                                <div className="d-flex justify-content-center">
                                    {/* <div className="col-4 d-flex" >
                                    <Link className="nav-link  justify-content-center " style={{ borderRadius: "3% 45% 13% 9%/35% 9% 7% 10%", backgroundColor: "#ffff", color: "#E64A9D" }} to="/career">Know more</Link>
                                </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About
