export default [
  {
    Id: 1,
    imgsrc: require(`../../images/1.jpg`),
    spinner: "text-primary",
  },
  {
    Id: 2,
    imgsrc: require(`../../images/2.jpg`),
    spinner: "text-secondary",
  },
  // {
  //   Id: 3,
  //   imgsrc: require(`../../images/3.jpg`),
  //   spinner: "text-success",
  // },
  // {
  //     Id: 4,
  //     imgsrc: require(`../../images/card4.jpg`),
  //     spinner:"text-danger",
  // },

  {
    Id: 6,
    imgsrc: require(`../../images/5.jpg`),
    spinner: "text-info",
  },

  // {
  //     Id: 9,
  //     imgsrc: require(`../../images/gallery/2.png`),
  //     spinner:"text-light",
  // },
  {
    Id: 10,
    imgsrc: require(`../../images/8.jpg`),
    spinner: "text-light",
  },
  // {
  //     Id: 11,
  //     imgsrc: require(`../../images/gallery/4.png`),
  //     spinner:"text-light",
  // },
  // {
  //   Id: 12,
  //   imgsrc: require(`../../images/gallery/5.png`),
  //   spinner: "text-light",
  // },
  // {
  //   Id: 13,
  //   imgsrc: require(`../../images/gallery/6.png`),
  //   spinner: "text-light",
  // },
  // {
  //   Id: 14,
  //   imgsrc: require(`../../images/gallery/7.png`),
  //   spinner: "text-light",
  // },
  // {
  //   Id: 15,
  //   imgsrc: require(`../../images/gallery/8.png`),
  //   spinner: "text-light",
  // },
  // {
  //   Id: 16,
  //   imgsrc: require(`../../images/gallery/9.png`),
  //   spinner: "text-light",
  // },
  // {
  //   Id: 17,
  //   imgsrc: require(`../../images/gallery/10.png`),
  //   spinner: "text-light",
  // },
  // {
  //   Id: 18,
  //   imgsrc: require(`../../images/gallery/11.png`),
  //   spinner: "text-light",
  // },
  // {
  //   Id: 19,
  //   imgsrc: require(`../../images/gallery/12.png`),
  //   spinner: "text-light",
  // },
  // {
  //   Id: 20,
  //   imgsrc: require(`../../images/gallery/13.png`),
  //   spinner: "text-light",
  // },
  // {
  //   Id: 21,
  //   imgsrc: require(`../../images/gallery/14.png`),
  //   spinner: "text-light",
  // },
  // {
  //   Id: 22,
  //   imgsrc: require(`../../images/gallery/15.png`),
  //   spinner: "text-light",
  // },
  // {
  //   Id: 23,
  //   imgsrc: require(`../../images/gallery/16.png`),
  //   spinner: "text-light",
  // },
];

export const lagreImage = [
  {
    Id: 5,
    imgsrc: require(`../../images/4.jpg`),
    spinner: "text-warning",
  },
  {
    Id: 7,
    imgsrc: require(`../../images/6.jpg`),
    spinner: "text-light",
  },
  {
    Id: 8,
    imgsrc: require(`../../images/7.jpg`),
    spinner: "text-light",
  },
];
