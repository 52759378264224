import React from 'react';
import "./thanks.css"
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Thanks = () => {
    // <Helmet>
    //     <script>
    //         gtag('event', 'conversion', {'send_to' : 'AW-10808994388/Wr5JCITzyoMDENTUkKIo'});
    //     </script>
    // </Helmet>
    return (
        <>
            <section class="login-main-wrapper">
                <div class="main-container">
                    <div class="login-process">
                        <div class="login-main-container">
                            <div class="thankyou-wrapper">
                                <h1><img src="http://montco.happeningmag.com/wp-content/uploads/2014/11/thankyou.png" alt="thanks" /></h1>
                                <p >for contacting us, we will get in touch with you soon... </p>
                                <Link to="/" className="borderradiuscurve navlinkdesbtn2" style={{ backgroundColor: "#F36C38", font: "20px Yomogi,cursive", fontWeight: "600" }}>Back to home</Link>
                                <div class="clr"></div>
                            </div>
                            <div class="clr"></div>
                        </div>
                    </div>
                    <div class="clr"></div>
                </div>
            </section>

        </>
    )
}

export default Thanks
