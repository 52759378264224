import React from 'react'
import { Helmet } from 'react-helmet'

const ProgramOffered = () => {


    const programmeOfferedData = [
        {
            Id: 1,
            title: "PG",
            programmedesc: `We offer a Pre-Kindergarten (PG) program designed for children to prepare for formal schooling. Our PG program provides numerous benefits, including opportunities for socialization, language development, and early learning skills.  `,
            color: "#009846"
        },
        {
            Id: 2,
            title: "Nursery",
            programmedesc: `The foundation for all successive educational levels is laid in nursery schools. With enjoyable games and activities, kids learn the fundamentals of education, including the alphabet, numbers, nursery rhymes, etc.`,
            color: "#00A0E3"
        },
        {
            Id: 3,
            title: "LKG ",
            programmedesc: `This program is defined for kids in the age group of 4-5. The Nursery programme allows the abilities to be refined through experimentation with the next level of learning when previously learned concepts meet application in the LKG programme.`,
            color: "#E5097F"
        },
        {
            Id: 4,
            title: "UKG",
            programmedesc: `The U.K.G. (Upper Kindergarten) programme level divides activities that are appropriate for development into numerous classes and sessions in an effort to prepare the kids for as well as further boost their self-confidence and self-esteem.`,
            color: "#009846"
        },
        {
            Id: 5,
            title: "Daycare and after school program",
            programmedesc: `Daycare is beneficial for working parents where kids receive a variety of entertaining and educational activities, such as singing and storytelling. Particularly for toddlers, these activities are essential to a child's intellectual growth and development.`,
            color: "#00A0E3"
        }
    ]




    return (
        <>
            <Helmet>
                <title>Sunrise Play School | Programme Offered</title>
                <meta name="description" content="Sunrise Play School is a premium preschool that runs under the guidance of education professionals with a combined experience of over 30 years. Our focus is on fostering learning experiences for students." />
                <meta name="keywords" content="Sunrise Play School, Pre School, Franchise"></meta>
            </Helmet>

            <div className="row align-items-center text-center aboutustopbg" id="contacttopbg">
                <div className="col-12 mt-5">
                    <h1 className='Programme-Offered'>Programme Offered</h1>
                    <p className="navlinkdesbtn2">Sunrise play school offers PG, Nursery, LKG, UKG, and Day Care programs for children and is  committed to impacting their life in a positive way. Our experienced teachers provide a safe and nurturing environment that encourages children to learn, grow, and play. We believe in fostering a community where each child can thrive and reach their full potential.</p>
                </div>
            </div>

            {/* Offered Programme Section */}

            <div className="col-12 text-center abutusdiv ">
                <h1 style={{ fontSize: "55px", fontWeight: "900", color: "#009846" }}>Programme Offered By <span className="funfont " style={{ fontSize: "55px" }}>Sunrise  Play School</span> ?</h1>
            </div>




            <div className="row text-center px-5 justify-content-around  learnboxx ">


                {
                    programmeOfferedData.map((p) => (
                        <>
                            <div className='col-md-4 col-12 p-md-5 p-1 d-flex align-items-stretch'>
                                <div className="borderradiuscurve " key={p.Id} style={{ backgroundColor: `${p.color}`, padding: "40px 20px" }}>
                                    <h2 style={{ color: "white", fontWeight: "800" }}>{p.title}</h2>
                                    <p className="text-white">
                                        {p.programmedesc}
                                    </p>
                                </div>
                            </div>

                        </>
                    ))
                }

            </div>

        </>
    )
}

export default ProgramOffered