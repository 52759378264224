import React from 'react'
import "./home.css";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
// import "../../../src/components/site.css"

const Home = () => {
    return (
        <div className="home">
            <Helmet>
                <title>SunrisePlaySchool | Home</title>
                <meta name="description" content="Sunrise Play School and Day Care is a premium preschool that runs under the guidance of education professionals with a combined experience of over 30 years. Our focus is on fostering learning experiences for students." />
                <meta name="keywords" content="Sunrise Play  School, Pre School, Home"></meta>
            </Helmet>

            <div className="col-12 text-center abutusdiv py-5">
                <h1 style={{ fontSize: "55px", fontWeight: "900", color: "#009846" }}>Why choose <span className="funfont break" style={{ fontSize: "55px" }}>Sunrise Play School and Day Care</span> ?</h1>
            </div>
            <div className="row">
                <div className="col-md-4 col-12 mt-4 img_div1" >
                    <div style={{ padding: "25px", position: 'absolute', bottom: "0" }}>
                        <div className="text-white borderradiuscurve text-center" style={{ backgroundColor: "#F47736", padding: "10px 20px",height:'330px' }}>
                            <h2 style={{ color: "white", fontWeight: "800" }}>Cerebral and Physical Development</h2>
                            <p className="text-white">
                                Our innovative environment promotes your child’s cerebral development through in-depth comprehension, inquiry, recollection, and application of concepts. 
                            </p>
                            <p className="text-white">
                                 Nurturing the body is equally important which is why we inculcate exercise, a balanced diet and personal hygiene in our school. 
                            </p>
                        </div>
                    </div>
                </div> <div className="col-md-4 col-12 mt-4 img_div2" >
                    <div style={{ padding: "25px", position: 'absolute', bottom: "0" }}>
                        <div className="borderradiuscurve text-center" style={{ backgroundColor: "#F47736", padding: "10px 20px", height:'330px' }}>
                        <h2 style={{ color: "white", fontWeight: "800" }}>Social and Emotional Development</h2>
                            <p className="text-white">
                                Through our curriculum, a child learns essential life skills like having healthy personal relationships, efficient communication, and a desire to serve humanity.
                            </p>
                            <p className="text-white">
                                This aids them in forming emotional and social connections with others.
                            </p>

                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-12 mt-4 img_div3" >
                    <div style={{ padding: "25px", position: 'absolute', bottom: "0" }}>
                        <div className="borderradiuscurve text-center" style={{ backgroundColor: "#F47736", padding: "10px 20px", height:'330px' }}>
                        <h2 style={{ color: "white", fontWeight: "800" }}>Spiritual and Holistic Development</h2>
                            <p className="text-white">
                                As they share their surroundings with birds and butterflies, children learn to be one with nature.
                            </p>
                            <p className="text-white">
                                This experience is conducive to the child's spiritual and holistic development.
                            </p>

                        </div>
                    </div>
                </div>
            </div><br />
            <div className="row py-5" style={{ position: "relative" }}>
                <img style={{ position: "absolute", width: "28vw", top: "70vh" }} src={require("../../images/bg3.png")} />
                <img style={{ position: "absolute", width: "28vw", top: "0vh", right: "0" }} src={require("../../images/bg4.png")} />

                <div className="col-12 text-center abutusdiv py-5">
                    <h1 style={{ fontSize: "50px", fontWeight: "900", color: "#009846" }}>Know More  <span className="funfont break" style={{ fontSize: "50px" }}>About Us</span></h1>
                </div>
                <div className="col-12 text-center">
                    <iframe width="500" height="281" src="https://www.youtube.com/embed/LL3oH2XlF6c?si=3tgvwjMjWD1jTuJo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <div className="col-12 text-center">
                    <iframe width="500" height="281" src="https://www.youtube.com/embed/XMubj1T8DYs?si=xyeFDXVvouTcrSDQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <div className="row mt-5" style={{ padding: "50px 50px" }}>
                    <div className="col-12 col-md-3 text-center" style={{ paddingTop: "60px" }}>
                        <p className="catchup"><span className="borderradiuscurve" style={{ backgroundColor: "#F36C38", color: "white", padding: "5px", fontSize: "30px" }}>CATCH UP</span>
                        </p>
                        <br />
                        <p className="fun"><span className="borderradiuscurve funfont" style={{ backgroundColor: "#00A0E3", color: "white", padding: "5px", fontSize: "47px" }}>&amp; Join the
                            Fun</span></p>
                    </div>
                    <div className="col-12 col-md-9 text-center borderradiuscurve p-4" style={{ backgroundColor: "#009846", color: "white" }}>
                        <p style={{ fontSize: "25px", color: "white" }}>Missed any of our amazing events? You can find all of our <span style={{ color: "white" }}>Sunrise Play School and Day Care EVENTS </span>on our Facebook page. Follow us for
                            regular learning and updates..</p>
                        <a href="https://www.facebook.com/sunriseplayschool.in/" className="btn borderradiuscurve" style={{ backgroundColor: "white", color: "#009846", fontWeight: "900" }}>Follow Us on Facebook</a>
                    </div>
                </div>
            </div>


            {/* <!-- Start your own play school --> */}
            <div className="row text-center" style={{ padding: '50px 0px' }} >
                <div className="col-12 text-center pb-3">
                    <h1 className="funfont homeopenplayschoolh1" style={{ color: "#F36C38" }}>
                        <img src={require("../../images/headingsupport.png")} style={{ width: '3vw', transform: 'rotate(180deg)', }} />
                        More About <span className="funfont" style={{ fontSize: '50px' }}>&nbsp;Sunrise Playschool and Day Care</span><img
                            src={require("../../images/headingsupport.png")} style={{ width: '3vw',transform: 'rotate(0deg)' }} />
                    </h1>
                </div>
                <div className="col-12 text-center firstfranchisediv">
                    <div className="row ">
                        <div className="col-12 col-md-6 Qualitybox  justify-content-center" >
                            <div className="borderradiuscurve text-left" style={{ backgroundColor: '#e5097eb4', padding: '30px' }} >
                                <h2 className="text-center" style={{ color: "white", fontWeight: "700" }}>Facilities</h2>
                                <p className="text-white">We relish that infrastructure influences the student’s attitude, behavior and learning that is why we provide- </p>
                                    <ul>
                                        <li className=" text-white">Right Lighting</li>
                                        <li className=" text-white">Safety and Security</li>
                                        <li className=" text-white">Anthropological and social aspects of the classroom and transitional spaces.</li>
                                        <li className=" text-white">Sensory stimulation harmony through the use of colorful patterns on walls and floors.</li>
                                    </ul>
                                <div className="d-flex justify-content-center">
                                {/* <div className="col-4 d-flex" >
                                    <Link className="nav-link  justify-content-center " style={{ borderRadius: "3% 45% 13% 9%/35% 9% 7% 10%", backgroundColor: "#ffff", color: "#E64A9D" }} to="/career">Know more</Link>
                                </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 text-center secondfranchisediv">
                    <div className="row">
                        <div className="col-12 col-md-6">

                        </div>
                        <div className="col-12 col-md-6 Qualitybox">
                            <div className="borderradiuscurve text-left" style={{ backgroundColor: '#009fe3b6', padding: '30px' }} >
                            <h2 className="text-center" style={{ color: "white", fontWeight: "700" }}>Faculty</h2>
                                <p className="text-white">All our teachers have undergone intensive training - </p>
                                    <ul>
                                        <li className=" text-white">They are certified and trained early education specialists.</li>
                                        <li className=" text-white">Our teachers excel in class planning and execution</li>
                                        <li className=" text-white">They identify individual learning patterns </li>
                                        <li className=" text-white">They cater to different learning levels in the same class.</li>
                                    </ul>
                                <div className="d-flex justify-content-center">
                                {/* <div className="col-4 d-flex" >
                                    <Link className="nav-link  justify-content-center " style={{ borderRadius: "3% 45% 13% 9%/35% 9% 7% 10%", backgroundColor: "#ffff", color: "#E64A9D" }} to="/career">Know more</Link>
                                </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 text-center thirdfranchisediv">
                    <div className="row">
                        <div className="col-12 col-md-6 Qualitybox">
                            <div className="borderradiuscurve text-left" style={{ backgroundColor: '#009847b6', padding: '30px' }} >
                            <h2 className="text-center" style={{ color: "white", fontWeight: "700" }}>Admission Procedure</h2>
                                <p className="text-white">We offer rolling enrolment throughout the year as long as we have room in our classrooms. Please note that admissions, meetings and school tours are available by appointment only- </p>
                                    <ul>
                                        <li className=" text-white">The Admission form.</li>
                                        <li className=" text-white">4 Photographs of the child in an envelope.</li>
                                        <li className=" text-white">2 Sets of parents' photographs. </li>
                                        <li className=" text-white">Birth Certificate issued by the appropriate authority.</li>
                                        <li className=" text-white">Aadhar card photocopy of the child, father and mother.</li>
                                    </ul>
                                <div className="d-flex justify-content-center">
                                {/* <div className="col-4 d-flex" >
                                    <Link className="nav-link  justify-content-center " style={{ borderRadius: "3% 45% 13% 9%/35% 9% 7% 10%", backgroundColor: "#ffff", color: "#E64A9D" }} to="/career">Know more</Link>
                                </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default Home
